import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
  palette: {
    primary: {
      main: '#03DAC5',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: 'white',
        },
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#f2f2f2',
          '&:hover': {
            backgroundColor: '#F2F2F2',
          },
        },
        input: {
          color: '#5A03DA',
          fontWeight: 'bold', // Change font weight
        },
        underline: {
          '&:after': {
            borderBottom: '2px solid #5A03DA',
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: '2px solid #d7adee',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        filled: {
          color: '#03DAC5', // Change label color
          '&.Mui-focused': {
            color: '#03DAC5', // Change label color when input is focused
          },
        },
      },
    },
  },
});
export default Theme;
