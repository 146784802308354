import { useAuth0 } from '@auth0/auth0-react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';

const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect();
  };

  return (
    <List>
      <ListItem onClick={handleLogin}>
        <ListItemIcon>
          <LoginIcon sx={{ color: 'black' }} />
        </ListItemIcon>
        <ListItemText primary="Login" />
      </ListItem>
    </List>
  );
};

export default LoginButton;
