import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import { Barbershop } from '../types/barbershop';
import { API_SERVER_URL } from '../constants/apiBaseUrlConstants';

interface UseBarbershopProps {
  barbershopId?: string;
  ownerId?: string;
  employeeId?: string;
  fetchAll?: boolean;
  providerIdToCheck?: string;
}

interface UseBarbershopReturn {
  barbershops: Barbershop[];
  selectedBarbershop: Barbershop | null;
  barbershopId: string | null;
  isLoading: boolean;
  error: string;
  refetch: () => Promise<void>;
}

const useBarbershop = ({
  barbershopId,
  ownerId,
  employeeId,
  fetchAll = false,
  providerIdToCheck,
}: UseBarbershopProps = {}): UseBarbershopReturn => {
  const [barbershops, setBarbershops] = useState<Barbershop[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const [currentProviderId, setCurrentProviderId] = useState<
    string | undefined
  >(undefined);

  const mounted = useRef(true);
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);
  const isInitialMount = useRef(true);
  const searchAttempts = useRef<Set<string>>(new Set());

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel('Component unmounted');
        cancelTokenSourceRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (providerIdToCheck) {
      setCurrentProviderId(providerIdToCheck);
    }
  }, [providerIdToCheck]);

  const handleApiError = useCallback((error: unknown) => {
    if (axios.isCancel(error)) {
      return 'Request cancelled';
    }

    if (error instanceof AxiosError) {
      // Don't treat 404 as an error during provider search
      if (error.response?.status === 404) {
        return '';
      }
      return error.response?.data?.message || error.message;
    }

    return 'Failed to load barbershop data';
  }, []);

  const normalizeBarbershopData = useCallback(
    (responseData: any): Barbershop[] => {
      if (!responseData) return [];

      return Array.isArray(responseData) ? responseData : [responseData];
    },
    []
  );

  const searchProvider = useCallback(
    async (
      providerId: string,
      searchType: 'owner' | 'employee',
      cancelToken: CancelTokenSource['token']
    ) => {
      const searchKey = `${providerId}-${searchType}`;
      if (searchAttempts.current.has(searchKey)) {
        return null;
      }

      searchAttempts.current.add(searchKey);
      const baseUrl = `${API_SERVER_URL}api/barbershops/search`;

      try {
        const url =
          searchType === 'owner'
            ? `${baseUrl}/owner`
            : `${baseUrl}/employee/${providerId}`;

        const config =
          searchType === 'owner'
            ? { params: { owner_id: providerId }, cancelToken }
            : { cancelToken };

        const response = await axios.get(url, config);

        if (response.data && Object.keys(response.data).length > 0) {
          return response.data;
        }

        return null;
      } catch (error) {
        if (axios.isCancel(error)) throw error;
        if (error instanceof AxiosError && error.response?.status === 404) {
          return null;
        }
        throw error;
      }
    },
    []
  );

  const fetchBarbershops = useCallback(async () => {
    if (!mounted.current) return;

    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel('New request initiated');
    }

    cancelTokenSourceRef.current = axios.CancelToken.source();
    searchAttempts.current.clear(); // Clear previous search attempts

    setIsLoading(true);
    setError('');

    try {
      let responseData = null;

      if (providerIdToCheck) {
        // Try owner search first
        responseData = await searchProvider(
          providerIdToCheck,
          'owner',
          cancelTokenSourceRef.current.token
        );

        // If no owner data found, try employee search
        if (!responseData) {
          responseData = await searchProvider(
            providerIdToCheck,
            'employee',
            cancelTokenSourceRef.current.token
          );
        }

        if (!responseData) {
          setBarbershops([]);
          return;
        }
      } else {
        const url = `${API_SERVER_URL}api/barbershops${
          barbershopId ? `/${barbershopId}` : ''
        }`;
        const response = await axios.get(url, {
          cancelToken: cancelTokenSourceRef.current.token,
          params: ownerId ? { owner_id: ownerId } : undefined,
        });
        responseData = response.data;
      }

      if (mounted.current && responseData) {
        const normalizedData = normalizeBarbershopData(responseData);

        setBarbershops(normalizedData);
        setError('');
      }
    } catch (error) {
      if (!axios.isCancel(error) && mounted.current) {
        const errorMessage = handleApiError(error);
        if (errorMessage) {
          console.error('[Barbershop] Error:', errorMessage);
          setError(errorMessage);
          setBarbershops([]);
        }
      }
    } finally {
      if (mounted.current) {
        setIsLoading(false);
      }
    }
  }, [
    barbershopId,
    ownerId,
    providerIdToCheck,
    handleApiError,
    normalizeBarbershopData,
    searchProvider,
  ]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchBarbershops();
    } else if (mounted.current) {
      fetchBarbershops();
    }
  }, [fetchBarbershops]);

  const selectedBarbershop = useMemo(() => {
    if (!barbershops.length) return null;

    if (barbershopId) {
      return (
        barbershops.find(shop => shop.barbershop_id === barbershopId) || null
      );
    }

    if (currentProviderId) {
      const shop = barbershops.find(
        shop => shop.owner_id === currentProviderId
      );

      return shop || barbershops[0];
    }

    return barbershops[0];
  }, [barbershops, barbershopId, currentProviderId]);

  const returnedBarbershopId = useMemo(() => {
    const id = selectedBarbershop?.barbershop_id || barbershopId || null;

    return id;
  }, [selectedBarbershop, barbershopId]);

  return {
    barbershops,
    selectedBarbershop,
    barbershopId: returnedBarbershopId,
    isLoading,
    error,
    refetch: fetchBarbershops,
  };
};

export default useBarbershop;
