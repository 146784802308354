import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import LoadingSpinner from '../components/animation/LoadingSpinner';
import { useAuth0 } from '@auth0/auth0-react';
import { UserRole } from '../types/authRoleInterface';

interface ProtectedRouteProps {
  children: ReactNode;
  userType: UserRole | null;
  requiredRole: UserRole;
  redirectPath?: string;
  requiresSubscription?: boolean;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  children,
  userType,
  requiredRole,
  redirectPath = '/unauthorized',
}) => {
  const { isAuthenticated, isLoading: auth0Loading } = useAuth0();

  console.log('ProtectedRoute State:', {
    userType,
    requiredRole,
    isAuthenticated,
    auth0Loading,
    currentPath: window.location.pathname,
  });

  // Wait for Auth0
  if (auth0Loading) {
    return <LoadingSpinner />;
  }

  // Handle authentication
  if (!isAuthenticated) {
    console.log('Redirecting: Not authenticated');
    return <Navigate to="/login" replace />; // Consider redirecting to login instead
  }

  // Wait for user role
  if (userType === null) {
    return <LoadingSpinner />;
  }

  // Check role match
  const hasRequiredRole = Array.isArray(requiredRole)
    ? requiredRole.includes(userType)
    : userType === requiredRole;

  if (!hasRequiredRole) {
    console.log('Redirecting: Invalid role', {
      userType,
      requiredRole,
      hasRequiredRole,
    });
    return <Navigate to={redirectPath} replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
