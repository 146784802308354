import { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Avatar,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { useAuth0 } from '@auth0/auth0-react';
import { MenuItems } from '../../../constants/MenuItems';
import { UserRole } from '../../../types/authRoleInterface';

export type BarberRole = Extract<UserRole, 'barber-owner' | 'barber-employee'>;

interface BarberDrawerProps {
  handleDrawerClose: () => void;
  userType: string;
}

const BarberDrawer: FC<BarberDrawerProps> = ({
  handleDrawerClose,
  userType,
}) => {
  const { user, logout } = useAuth0();

  const filteredMenuItems = MenuItems.filter(item =>
    item.roles.includes(userType as UserRole)
  );

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <>
      <Box
        sx={{
          my: 2,
          display: 'flex',
          alignItems: 'center',
          gap: '1.5rem',
          px: 2,
        }}
      >
        {user?.picture && (
          <ListItem sx={{ width: 'auto', padding: 0 }}>
            <ListItemIcon>
              <Avatar
                alt={user.name}
                src={user.picture}
                sx={{ width: 36, height: 36 }}
              />
            </ListItemIcon>
            <ListItemText
              primary={`Hi, ${user.given_name || user.nickname}`}
              primaryTypographyProps={{
                fontWeight: 'bold',
              }}
            />
          </ListItem>
        )}
      </Box>

      {filteredMenuItems.map(({ label, path, icon }) => (
        <ListItem
          key={path}
          component={Link}
          to={path}
          onClick={handleDrawerClose}
          sx={{
            color: 'black',
            textDecoration: 'none',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={label} />
        </ListItem>
      ))}

      <ListItem
        onClick={handleLogout}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        }}
      >
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
    </>
  );
};

export default BarberDrawer;
