import { useState, useEffect } from 'react';
import checkBarberSubscription from '../services/checkBarberSubscription';
import { useAuth0 } from '@auth0/auth0-react';

const useBarberSubscription = () => {
  const [isBarberSubscribed, setIsBarberSubscribed] = useState(false);
  const { user } = useAuth0();
  const barberId = user?.sub || user?.user_id;

  useEffect(() => {
    const fetchBarberSubscriptionStatus = async () => {
      if (barberId) {
        try {
          const res = await checkBarberSubscription(barberId);
          setIsBarberSubscribed(res);
        } catch (err) {
          console.log('error fetching barber subscription');
        }
      }
    };
    fetchBarberSubscriptionStatus();
  }, [user]);

  return isBarberSubscribed;
};

export default useBarberSubscription;
