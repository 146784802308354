import {
  Home,
  CalendarMonth,
  ModeEdit,
  Today,
  Payment,
  Payments,
  People,
  AccountTree,
  Campaign,
} from '@mui/icons-material';
import { UserRole } from '../types/authRoleInterface';
import { SvgIconProps } from '@mui/material';

export interface MenuItem {
  label: string;
  path: string;
  icon: JSX.Element;
  roles: UserRole[];
}

export const MenuItems: MenuItem[] = [
  {
    label: 'Dashboard',
    path: '/barber/dashboard',
    icon: <Home />,
    roles: ['barber-owner', 'barber-employee'],
  },
  {
    label: 'Management',
    path: '/barber/employee/management',
    icon: <AccountTree />,
    roles: ['barber-owner'],
  },
  {
    label: 'Announcements',
    path: '/barber/announcements',
    icon: <Campaign />,
    roles: ['barber-owner'],
  },
  {
    label: 'Schedule',
    path: '/barber/scheduler',
    icon: <CalendarMonth />,
    roles: ['barber-owner', 'barber-employee'],
  },
  {
    label: 'Services',
    path: '/barber/services',
    icon: <ModeEdit />,
    roles: ['barber-owner', 'barber-employee'],
  },
  {
    label: 'Appointments',
    path: '/barber/appointments',
    icon: <Today />,
    roles: ['barber-owner', 'barber-employee'],
  },
  {
    label: 'Cash Bookings',
    path: '/barber/booking/cashpayment',
    icon: <Payment />,
    roles: ['barber-owner', 'barber-employee'],
  },
  {
    label: 'Earnings',
    path: '/barber/earnings',
    icon: <Payments />,
    roles: ['barber-owner', 'barber-employee'],
  },
  {
    label: 'Clients',
    path: '/barber/clients',
    icon: <People />,
    roles: ['barber-owner', 'barber-employee'],
  },
];
