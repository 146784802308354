import { createContext, useState, useContext, ReactNode } from 'react';
import { PendingBookingDetails } from '../types/checkoutFormInterface';

interface PendingBookingContextType {
  pendingBookingDetails: PendingBookingDetails | null;
  setPendingBookingDetails: (details: PendingBookingDetails | null) => void;
}

const PendingBookingContext = createContext<
  PendingBookingContextType | undefined
>(undefined);

export const PendingBookingProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [pendingBookingDetails, setPendingBookingDetails] =
    useState<PendingBookingDetails | null>(null);

  return (
    <PendingBookingContext.Provider
      value={{ pendingBookingDetails, setPendingBookingDetails }}
    >
      {children}
    </PendingBookingContext.Provider>
  );
};

export const usePendingBooking = () => {
  const context = useContext(PendingBookingContext);
  if (context === undefined) {
    throw new Error(
      'usePendingBooking must be used within a PendingBookingProvider'
    );
  }
  return context;
};
