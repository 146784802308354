import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { io, Socket } from 'socket.io-client';
import { toast } from 'react-toastify';
import {
  SocketContextType,
  SocketProviderProps,
  SocketError,
} from '../types/socketContext';

export const SocketContext = createContext<SocketContextType | undefined>(
  undefined
);

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState<SocketError | null>(null);
  const reconnectAttempts = useRef(0);
  const MAX_RECONNECT_ATTEMPTS = 5;

  const SOCKET_URL = import.meta.env.VITE_SOCKET_URL;

  // Memoize error handler to prevent recreation on each render
  // const handleError = useMemo(
  //   () => ({
  //     set: (errorMessage: string, details?: any) => {
  //       const socketError: SocketError = {
  //         message: errorMessage,
  //         code: details?.code,
  //         details,
  //       };
  //       setError(socketError);
  //       toast.error(errorMessage);
  //     },
  //     clear: () => setError(null),
  //   }),
  //   []
  // );

  useEffect(() => {
    let mounted = true;

    const newSocket = io(SOCKET_URL, {
      secure: true,
      transports: ['websocket'],
      reconnection: true,
      reconnectionAttempts: MAX_RECONNECT_ATTEMPTS,
      reconnectionDelay: 1000,
    });

    newSocket.on('connect', () => {
      if (mounted) {
        setIsConnected(true);
        //handleError.clear();
        reconnectAttempts.current = 0;
        console.log('Socket connected');
      }
    });

    newSocket.on('disconnect', () => {
      if (mounted) {
        setIsConnected(false);
        //handleError.set('Socket disconnected. Attempting to reconnect...');
        console.log('Socket disconnected');
      }
    });

    // newSocket.on('connect_error', error => {
    //   if (mounted) {
    //     reconnectAttempts.current += 1;
    //     //handleError.set(`Connection error: ${error.message}`, error);

    //     if (reconnectAttempts.current >= MAX_RECONNECT_ATTEMPTS) {
    //       newSocket.disconnect();
    //       // handleError.set(
    //       //   'Maximum reconnection attempts reached. Please refresh the page.'
    //       // );
    //     }
    //   }
    // });

    // newSocket.on('error', error => {
    //   if (mounted) {
    //     // handleError.set(`Socket error: ${error.message}`, error);
    //     console.error(`Socket error: ${error.message}`);
    //   }
    // });

    setSocket(newSocket);

    return () => {
      mounted = false;
      newSocket.disconnect();
    };
  }, [SOCKET_URL]);

  const joinRoom = useCallback(
    (room: string) => {
      if (!socket) {
        //handleError.set('Cannot join room: Socket not connected');
        console.log('Cannot join room: Socket not connected');
        return;
      }

      try {
        socket.emit('join_room', room);
      } catch (err: any) {
        //handleError.set(`Failed to join room: ${err.message}`);
        console.log(`Failed to join room: ${err.message}`);
      }
    },
    [socket]
  );

  const leaveRoom = useCallback(
    (room: string) => {
      if (!socket) {
        //handleError.set('Cannot leave room: Socket not connected');
        console.error('Cannot leave room: Socket not connected');
        return;
      }

      try {
        socket.emit('leave_room', room);
      } catch (err: any) {
        //handleError.set(`Failed to leave room: ${err.message}`);
        console.log(`Failed to leave room: ${err.message}`);
      }
    },
    [socket]
  );

  const contextValue = useMemo(
    () => ({
      socket,
      isConnected,
      error,
      joinRoom,
      leaveRoom,
      //clearError: handleError.clear,
    }),
    [socket, isConnected, error, joinRoom, leaveRoom]
  );

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
};
