import { ReactElement } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { SyncLoader } from 'react-spinners';
import { COLORS } from '../../constants/brandColors';

interface LoadingSpinnerProps {
  SpinnerComponent?: React.FC<any>;
  size?: 'small' | 'medium' | 'large' | number;
  color?: string;
  text?: string;
  variant?: 'fullscreen' | 'inline' | 'contained';
  textVariant?: 'h6' | 'body1' | 'body2' | 'caption';
  showText?: boolean;
  centered?: boolean;
  [x: string]: any;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  SpinnerComponent,
  size = 'medium',
  color = COLORS.primary,
  text = 'Loading, please wait...',
  variant = 'fullscreen',
  textVariant = 'h6',
  showText = true,
  centered = true,
  ...props
}): ReactElement => {
  const theme = useTheme();

  // Convert size prop to actual number
  const getSpinnerSize = (): number => {
    if (typeof size === 'number') return size;
    switch (size) {
      case 'small':
        return 15;
      case 'large':
        return 45;
      default: // medium
        return 30;
    }
  };

  // Get container styles based on variant
  const getContainerStyles = () => {
    const baseStyles = {
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'center',
    };

    switch (variant) {
      case 'fullscreen':
        return {
          ...baseStyles,
          height: '100vh',
          margin: '160px 0',
          justifyContent: centered ? 'center' : 'flex-start',
        };
      case 'inline':
        return {
          ...baseStyles,
          display: 'inline-flex',
          flexDirection: 'row' as const,
          gap: theme.spacing(1),
          margin: 0,
        };
      case 'contained':
        return {
          ...baseStyles,
          padding: theme.spacing(2),
          minHeight: 100,
          justifyContent: 'center',
        };
      default:
        return baseStyles;
    }
  };

  return (
    <Box sx={getContainerStyles()}>
      {SpinnerComponent ? (
        <SpinnerComponent size={getSpinnerSize()} color={color} {...props} />
      ) : (
        <SyncLoader
          color={color}
          size={getSpinnerSize() / 2} // SyncLoader typically needs smaller sizes
        />
      )}
      {showText && (
        <Typography
          variant={textVariant}
          color="text.primary"
          component="div"
          sx={{
            mt: variant === 'inline' ? 0 : 2,
            fontSize: variant === 'inline' ? '0.875rem' : undefined,
          }}
        >
          {text}
        </Typography>
      )}
    </Box>
  );
};

export default LoadingSpinner;
