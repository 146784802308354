import { FC, ReactNode } from 'react';
import { LoadingProvider } from '../context/LoadingContext';
import { SocketProvider } from '../context/SocketContext';
import { AppointmentsProvider } from '../context/AppointmentsContext';
import { PendingBookingProvider } from '../context/PendingBookingContext';

export const AppProviders: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <LoadingProvider>
      <SocketProvider>
        <AppointmentsProvider>
          <PendingBookingProvider>{children}</PendingBookingProvider>
        </AppointmentsProvider>
      </SocketProvider>
    </LoadingProvider>
  );
};
