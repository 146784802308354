import { lazy } from 'react';
import type { LazyExoticComponent, ComponentType } from 'react';
import type { UserRole } from '../types/authRoleInterface';

export interface RouteConfig {
  path: string;
  component: LazyExoticComponent<ComponentType<any>>;
  roles?: UserRole[];
}

export const sharedBarberRoutes: RouteConfig[] = [
  {
    path: '/barber/dashboard',
    component: lazy(() => import('../pages/barber/BarberDashboardPage')),
    roles: ['barber-owner', 'barber-employee'],
  },
  {
    path: '/barber/scheduler',
    component: lazy(() => import('../pages/barber/BarberSchedulerPage')),
    roles: ['barber-owner', 'barber-employee'],
  },
  {
    path: '/barber/services',
    component: lazy(() => import('../pages/CreateServicesPage')),
    roles: ['barber-owner', 'barber-employee'],
  },
  {
    path: '/barber/appointments',
    component: lazy(() => import('../pages/barber/BarberViewAppointmentsPage')),
    roles: ['barber-owner', 'barber-employee'],
  },
  {
    path: '/barber/clients',
    component: lazy(() => import('../pages/ClientsPage')),
    roles: ['barber-owner', 'barber-employee'],
  },
  {
    path: '/barber/booking/cashpayment',
    component: lazy(
      () => import('../components/features/cashBookings/CashPayment')
    ),
    roles: ['barber-owner', 'barber-employee'],
  },
  {
    path: '/barber/earnings',
    component: lazy(
      () => import('../components/features/barberEarnings/EarningsPage')
    ),
    roles: ['barber-owner', 'barber-employee'],
  },
];

// Owner-specific routes
export const ownerOnlyRoutes: RouteConfig[] = [
  {
    path: '/barber/announcements',
    component: lazy(() => import('../pages/CreateServicesPage')),
    roles: ['barber-owner'],
  },
  {
    path: '/barber/setup-account',
    component: lazy(
      () => import('../components/features/shopManagemet/MultiStepForm')
    ),
    roles: ['barber-owner'],
  },
  {
    path: '/barber/employee/management',
    component: lazy(
      () =>
        import('../components/features/EmployeeManagement/EmployeeManagement')
    ),
    roles: ['barber-owner'],
  },
];

const routes = {
  public: [
    { path: '/', component: lazy(() => import('../pages/HomePage')) },

    {
      path: '/guest/appointment/:link',
      component: lazy(() => import('../pages/guest/GuestAppointmentPage')),
    },
    {
      path: '/barbershops/:shopId',
      component: lazy(() => import('../components/features/BarbershopDetails')),
    },
    {
      path: '/barbershops/:shopId/barber/:barberId',
      component: lazy(() => import('../pages/barber/BarberProfilePage')),
    },
    {
      path: '/barbershops/:shopId/barbers/:barberId/book',
      component: lazy(
        () => import('../components/features/bookings/BookAppointmentContainer')
      ),
    },
    {
      path: '/professionals/barber',
      component: lazy(() => import('../pages/barber/BarberLandingPage')),
    },
    {
      path: '/payment/:planId',
      component: lazy(
        () => import('../components/features/subscriptions/PaymentPage')
      ),
    },
    {
      path: '/success',
      component: lazy(() => import('../components/common/SuccessPage')),
    },
    {
      path: '/payment-status',
      component: lazy(() => import('../pages/PaymentStatusPage')),
    },
  ],
  customer: [
    {
      path: '/user/dashboard',
      component: lazy(() => import('../pages/user/UserDashboardPage')),
    },
    {
      path: '/user/live',
      component: lazy(() => import('../pages/user/UserLiveTrackingPage')),
    },
    {
      path: '/user/confirmation',
      component: lazy(() => import('../pages/ConfirmationPage')),
    },
    {
      path: '/user/barberProfile/:id',
      component: lazy(() => import('../pages/barber/BarberProfilePage')),
    },
  ],
  barberOwner: [...sharedBarberRoutes, ...ownerOnlyRoutes],
  barberEmployee: [...sharedBarberRoutes],
} as const;

export default routes;
