import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LoginIcon from '@mui/icons-material/Login';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LogoutIcon from '@mui/icons-material/Logout';

import { useAuth0 } from '@auth0/auth0-react';

const UserDrawer: React.FC = ({}) => {
  const { user, isAuthenticated, logout } = useAuth0();
  const navigate = useNavigate();

  return (
    <>
      {isAuthenticated && (
        <List>
          <Box
            sx={{
              marginLeft: '1rem',
              my: 2,
              display: 'flex',
              alignItems: 'center',
              gap: '1.5rem',
            }}
          >
            {user && user.picture && (
              <>
                <Avatar
                  alt={user?.given_name}
                  src={user?.picture}
                  sx={{ width: 36, height: 36 }}
                />
                <p>Hi, {user?.given_name || user?.nickname}</p>
              </>
            )}
          </Box>
          <ListItem onClick={() => navigate('/user/dashboard')}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>

          <ListItem onClick={() => logout()}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      )}
    </>
  );
};

export default UserDrawer;
