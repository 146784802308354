import { useCallback } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { Alert, Button, Box } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

interface FallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <Box
      sx={{
        p: 2,
        maxWidth: 600,
        mx: 'auto', // horizontal margin auto
        mt: { xs: 7, sm: 8, md: 9 }, // responsive margin top
        position: 'relative',
        zIndex: 1, // ensure it's above other content if needed
      }}
    >
      <Alert
        severity="error"
        action={
          <Button
            color="inherit"
            size="small"
            onClick={resetErrorBoundary}
            startIcon={<RefreshIcon />}
          >
            Retry
          </Button>
        }
      >
        Something went wrong. Please try again.
      </Alert>
    </Box>
  );
};

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  const handleReset = useCallback(() => {
    // Can add additional reset logic here
    window.location.reload();
  }, []);

  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={handleReset}
      onError={(error, info) => {
        // Log error to error reporting service
        console.error('Error caught by boundary:', error, info);
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
