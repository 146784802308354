import { FC, useState } from 'react';
import {
  Avatar,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  useMediaQuery,
  useTheme,
  IconButton,
  Drawer,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  Menu as MenuIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ExitToApp as ExitToAppIcon,
} from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';

import { COLORS } from '../../constants/brandColors';
import useUserRole from '../../hooks/useUserRole';
import LoadingSpinner from '../../components/animation/LoadingSpinner';
import { useNavigationHandlers } from '../../hooks/useNavigationHandlers';
import UserDrawer from './drawer/UserDrawer';
import BarberDrawer from './drawer/BarberDrawer';
import { MenuItems } from '../../constants/MenuItems';
import { UserRole } from '../../types/authRoleInterface';
import LoginButton from '../../components/common/LoginButton';

const Navigation: FC = () => {
  const { handleDrawerOpen, handleDrawerClose, open } = useNavigationHandlers();
  const { isAuthenticated, user, logout, loginWithRedirect } = useAuth0();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const [userType, loading] = useUserRole();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    handleMenuClose();
  };

  const getFilteredMenuItems = () => {
    if (!userType) return [];
    return MenuItems.filter(item => item.roles.includes(userType as UserRole));
  };

  const renderDrawerContent = () => {
    if (!isAuthenticated) {
      return <LoginButton />;
    }

    if (!userType) return null;

    switch (userType) {
      case 'barber-owner':
      case 'barber-employee':
        return (
          <BarberDrawer
            handleDrawerClose={handleDrawerClose}
            userType={userType}
          />
        );
      case 'customer':
        return <UserDrawer />;
      default:
        return (
          <Button
            color="inherit"
            onClick={handleLogout}
            sx={{ color: 'black', width: '100%' }}
          >
            Logout
          </Button>
        );
    }
  };

  const renderLargeScreenMenu = () => {
    if (!isAuthenticated) return null;

    const filteredItems = getFilteredMenuItems();

    return (
      <>
        <Button
          color="inherit"
          onClick={handleMenuClick}
          endIcon={<ArrowDropDownIcon sx={{ color: 'white' }} />}
          sx={{ color: 'white' }}
        >
          Menu
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
            },
          }}
        >
          {filteredItems.map(item => (
            <MenuItem
              key={item.path}
              onClick={() => {
                navigate(item.path);
                handleMenuClose();
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: 'black' }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!isLargeScreen && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, color: 'white' }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            variant="h6"
            color={COLORS.primary}
            sx={{ fontWeight: '800', cursor: 'pointer' }}
            onClick={() => navigate('/')}
          >
            Donvio
          </Typography>
        </Box>

        {isLargeScreen && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {!isAuthenticated && (
              <Button
                color="inherit"
                onClick={() => navigate('professionals/barber')}
                sx={{ color: 'white' }}
              >
                For Business
              </Button>
            )}
            {renderLargeScreenMenu()}
            {isAuthenticated && user?.picture && (
              <Avatar
                alt={user?.name}
                src={user?.picture}
                sx={{
                  width: 34,
                  height: 34,
                }}
              />
            )}
            {!isAuthenticated && (
              <Button
                color="inherit"
                onClick={() => loginWithRedirect()}
                sx={{ color: 'white' }}
              >
                Login
              </Button>
            )}
          </Box>
        )}
      </Toolbar>

      <Drawer
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
        variant="temporary"
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={handleDrawerClose}
          onKeyDown={handleDrawerClose}
        >
          {renderDrawerContent()}
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Navigation;
