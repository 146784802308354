import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Container, Typography, Button, Stack } from '@mui/material';
import useUserRole from '../hooks/useUserRole';

const UnauthorizedPage: FC = () => {
  const [userType] = useUserRole();
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const handleHomeClick = () => {
    const homePath =
      userType === 'customer' ? '/user/dashboard' : '/barber/dashboard';
    navigate(homePath);
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        bgcolor: 'grey.50',
      }}
    >
      <Container maxWidth="sm">
        <Box sx={{ textAlign: 'center', p: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            Unauthorized Access
          </Typography>

          <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
            You don't have permission to access this page.
          </Typography>

          <Stack spacing={2} sx={{ mt: 4 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleHomeClick}
              fullWidth
            >
              Go to Dashboard
            </Button>

            <Button
              variant="outlined"
              color="inherit"
              onClick={() =>
                logout({
                  logoutParams: { returnTo: window.location.origin },
                })
              }
              fullWidth
            >
              Logout
            </Button>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default UnauthorizedPage;
