import { FC, ReactNode } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Navigation from '../../routes/navigation/Navigation';
import 'react-toastify/dist/ReactToastify.css';

const AppLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <Navigation />
      <main className="app-main">{children}</main>
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </>
  );
};

export default AppLayout;
