import { useEffect } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Suspense } from 'react';

import { AppProviders } from './providers/AppProviders';
import { RouterConfig } from './config/routerConfig';
import Theme from './styles/Theme';
import AppLayout from './components/layout/AppLayout';
import LoadingSpinner from './components/animation/LoadingSpinner';
import ErrorBoundary from './components/common/ErrorBoundary';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY_TEST);

function App() {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={Theme}>
        <AppProviders>
          <Elements stripe={stripePromise}>
            <BrowserRouter>
              <AppLayout>
                <Suspense fallback={<LoadingSpinner />}>
                  <ErrorBoundary>
                    <RouterConfig />
                  </ErrorBoundary>
                </Suspense>
              </AppLayout>
            </BrowserRouter>
          </Elements>
        </AppProviders>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
