import { FC, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import ProtectedRoute from '../routes/ProtectedRoute';
import PageNotFound from '../routes/404/PageNotFound';
import routes from './routes';
import UnauthorizedPage from '../routes/UnauthorizedPage';
import useUserRole from '../hooks/useUserRole';
import useBarberSubscription from '../hooks/useBarberSubscription';
import { useAuth0 } from '@auth0/auth0-react';
import { UserRole } from '../types/authRoleInterface';
import LoadingSpinner from '../components/animation/LoadingSpinner';

import { sharedBarberRoutes, ownerOnlyRoutes } from './routes';

export const RouterConfig: FC = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const [userType, loading] = useUserRole();
  const isBarberSubscribed = useBarberSubscription();

  console.log('RouterConfig:', {
    isAuthenticated,
    userType,
    loading,
    isBarberSubscribed,
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Routes>
      {/* Public Routes */}
      {routes.public.map(({ path, component: Component }) => (
        <Route
          key={path}
          path={path}
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <Component />
            </Suspense>
          }
        />
      ))}

      {/* Customer Routes */}
      {routes.customer.map(({ path, component: Component }) => (
        <Route
          key={path}
          path={path}
          element={
            <ProtectedRoute
              userType={userType}
              requiredRole="customer"
              redirectPath="/unauthorized"
            >
              <Suspense fallback={<LoadingSpinner />}>
                <Component />
              </Suspense>
            </ProtectedRoute>
          }
        />
      ))}

      {/* Shared Barber Routes (Both owner and employee) */}
      {sharedBarberRoutes.map(({ path, component: Component, roles = [] }) => (
        <Route
          key={path}
          path={path}
          element={
            <ProtectedRoute
              userType={userType}
              requiredRole={userType as UserRole}
              redirectPath="/unauthorized"
            >
              <Suspense fallback={<LoadingSpinner />}>
                {userType === 'barber-owner' && !isBarberSubscribed ? (
                  <Navigate to="/professionals/barber" replace />
                ) : (
                  <Component />
                )}
              </Suspense>
            </ProtectedRoute>
          }
        />
      ))}

      {/* Owner-Only Routes */}
      {ownerOnlyRoutes.map(({ path, component: Component }) => (
        <Route
          key={path}
          path={path}
          element={
            <ProtectedRoute
              userType={userType}
              requiredRole="barber-owner"
              redirectPath="/unauthorized"
            >
              <Suspense fallback={<LoadingSpinner />}>
                {!isBarberSubscribed ? (
                  <Navigate to="professionals/barber" replace />
                ) : (
                  <Component />
                )}
              </Suspense>
            </ProtectedRoute>
          }
        />
      ))}

      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
