import axios from 'axios';
import { API_SERVER_URL } from '../constants/apiBaseUrlConstants';
import { Appointment, RescheduleData } from '../types/appointments';

export const appointmentsApi = {
  getUserAppointments: async (
    userId: string,
    barbershopId: string
  ): Promise<Appointment[]> => {
    const response = await axios.get(
      `${API_SERVER_URL}api/users/${userId}/barbershops/${barbershopId}/appointments`
    );

    return response.data;
  },

  getBarberAppointments: async (
    barbershopId: string,
    barberId: string
  ): Promise<Appointment[]> => {
    const response = await axios.get(
      `${API_SERVER_URL}api/barbershops/${barbershopId}/barbers/${barberId}/appointments`
    );

    return response.data;
  },

  rescheduleAppointment: async (
    appointmentId: number,
    userId: string | undefined,
    newData: RescheduleData
  ): Promise<any> => {
    const response = await axios.put(
      `${API_SERVER_URL}api/users/${userId}/appointments/${appointmentId}/reschedule`,
      { ...newData, appointmentId }
    );
    return response.data;
  },
};
