import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import useBarbershop from '../hooks/useBarbershop';
import { appointmentsApi } from '../services/appointments';
import { Appointment, RescheduleData } from '../types/appointments';

interface AppointmentsError {
  message: string;
  code?: string;
  details?: any;
}

interface UseAppointmentsReturn {
  userAppointments: Appointment[];
  barberAppointments: Appointment[];
  isLoading: boolean;
  error: AppointmentsError | null;
  isFetching: boolean;
  fetchUserAppointments: (userId: string) => Promise<void>;
  fetchBarberAppointments: (barberId: string) => Promise<void>;
  removeAppointment: (appointmentId: number) => void;
  rescheduleAppointment: (
    appointmentId: number,
    newData: RescheduleData
  ) => Promise<void>;
  clearError: () => void;
}

export const useAppointments = (isBarber?: boolean): UseAppointmentsReturn => {
  // Use refs to track initialization state
  const isInitialized = useRef(false);
  const initialFetchComplete = useRef(false);

  const [userAppointments, setUserAppointments] = useState<Appointment[]>([]);
  const [barberAppointments, setBarberAppointments] = useState<Appointment[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<AppointmentsError | null>(null);

  const { user, isAuthenticated } = useAuth0();
  const { barbershopId, isLoading: isBarbershopLoading } = useBarbershop();

  const userId = useMemo(
    () => user?.sub || user?.user_id,
    [user?.sub, user?.user_id]
  );

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  const handleError = useCallback((error: any, context: string) => {
    const errorMessage =
      error?.response?.data?.message ||
      error?.message ||
      `Failed during ${context}`;

    setError({
      message: errorMessage,
      code: error?.response?.status?.toString(),
      details: error?.response?.data,
    });
  }, []);

  const fetchUserAppointments = useCallback(
    async (userId: string) => {
      if (!barbershopId) {
        return;
      }

      setIsFetching(true);
      clearError();

      try {
        const data = await appointmentsApi.getUserAppointments(
          userId,
          barbershopId
        );

        // Only update if the data actually changed
        setUserAppointments(prev => {
          const hasChanged = JSON.stringify(prev) !== JSON.stringify(data);
          return hasChanged ? data : prev;
        });
      } catch (error: any) {
        handleError(error, 'fetching user appointments');
      } finally {
        setIsFetching(false);
        setIsLoading(false);
        initialFetchComplete.current = true;
      }
    },
    [barbershopId, handleError, clearError]
  );

  const fetchBarberAppointments = useCallback(
    async (barberId: string) => {
      if (!barbershopId) {
        return;
      }

      setIsFetching(true);
      clearError();

      try {
        const data = await appointmentsApi.getBarberAppointments(
          barbershopId,
          barberId
        );

        // Only update if the data actually changed
        setBarberAppointments(prev => {
          const hasChanged = JSON.stringify(prev) !== JSON.stringify(data);
          return hasChanged ? data : prev;
        });
      } catch (error: any) {
        handleError(error, 'fetching barber appointments');
      } finally {
        setIsFetching(false);
        setIsLoading(false);
        initialFetchComplete.current = true;
      }
    },
    [barbershopId, handleError, clearError]
  );

  const removeAppointment = useCallback(
    (appointmentId: number) => {
      try {
        setUserAppointments(prev =>
          prev.filter(appointment => appointment.id !== appointmentId)
        );
        setBarberAppointments(prev =>
          prev.filter(appointment => appointment.id !== appointmentId)
        );
      } catch (error: any) {
        handleError(error, 'removing appointment');
      }
    },
    [handleError]
  );

  const rescheduleAppointment = useCallback(
    async (appointmentId: number, newData: RescheduleData) => {
      if (!userId) {
        handleError(
          new Error('User not authenticated'),
          'rescheduling appointment'
        );
        return;
      }

      setIsFetching(true);
      clearError();

      try {
        await appointmentsApi.rescheduleAppointment(
          appointmentId,
          userId,
          newData
        );

        // Only fetch if we've already completed initial fetch
        if (initialFetchComplete.current) {
          if (isBarber) {
            await fetchBarberAppointments(userId);
          } else {
            await fetchUserAppointments(userId);
          }
        }

        toast.success('Appointment rescheduled successfully');
      } catch (error: any) {
        handleError(error, 'rescheduling appointment');
      } finally {
        setIsFetching(false);
      }
    },
    [
      userId,
      fetchUserAppointments,
      fetchBarberAppointments,
      handleError,
      clearError,
      isBarber,
    ]
  );

  const initializeAppointments = useCallback(async () => {
    if (
      isInitialized.current ||
      isBarbershopLoading ||
      !isAuthenticated ||
      !userId
    ) {
      return;
    }

    isInitialized.current = true;

    try {
      if (isBarber) {
        await fetchBarberAppointments(userId);
      } else {
        await fetchUserAppointments(userId);
      }
    } catch (error: any) {
      handleError(error, 'initialization');
    }
  }, [
    isBarbershopLoading,
    isAuthenticated,
    userId,
    isBarber,
    fetchUserAppointments,
    fetchBarberAppointments,
    handleError,
  ]);

  useEffect(() => {
    initializeAppointments();

    return () => {
      isInitialized.current = false;
      initialFetchComplete.current = false;
    };
  }, [initializeAppointments]);

  return {
    userAppointments,
    barberAppointments,
    isLoading,
    error,
    isFetching,
    fetchUserAppointments,
    fetchBarberAppointments,
    removeAppointment,
    rescheduleAppointment,
    clearError,
  };
};
