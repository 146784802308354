import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useSocket } from './useSocket';
import {
  AppointmentData,
  ProgressUpdateData,
  AppointmentTimerUpdate,
  Cleanup,
} from '../types/socketContext';
import { SocketError, UseAppointmentSocketProps } from '../types/socketContext';

export const useUserSocket = ({
  userId,
  guestLink,
  appointmentId,
}: UseAppointmentSocketProps) => {
  const {
    socket,
    joinRoom,
    leaveRoom,
    isConnected,
    error: socketError,
  } = useSocket();
  const [error, setError] = useState<SocketError | null>(null);
  const roomRef = useRef<string | null>(null);
  const listenersSetRef = useRef(false);

  // Memoize error handler
  // const handleError = useMemo(
  //   () => ({
  //     set: (errorMessage: string, details?: any) => {
  //       const newError: SocketError = {
  //         message: errorMessage,
  //         code: details?.code,
  //         details,
  //       };
  //       setError(newError);
  //     },
  //     clear: () => setError(null),
  //   }),
  //   []
  // );

  useEffect(() => {
    let mounted = true;

    // if (!socket || !userId) {
    //   if (!socket && mounted) handleError.set('Socket not initialized');
    //   if (!userId && mounted) handleError.set('User ID not available');
    //   return;
    // }

    if (!socket) return;

    const userRoom = `user_${userId}`;
    if (roomRef.current !== userRoom) {
      if (roomRef.current) {
        leaveRoom(roomRef.current);
      }

      try {
        joinRoom(userRoom);
        roomRef.current = userRoom;
      } catch (err: any) {
        //if (mounted) handleError.set(`Failed to join room: ${err.message}`);
        console.error(`Failed to join room: ${err.message}`);
      }
    }

    if (!listenersSetRef.current) {
      const setupListeners = () => {
        socket.on('next_appointment_progress', (data: AppointmentData) => {
          if (mounted) console.log('Received next_appointment_updated:', data);
        });

        socket.on('appointment_progress', (data: ProgressUpdateData) => {
          if (mounted) console.log('Received appointment_progress:', data);
        });

        socket.on('user_timer', (data: AppointmentTimerUpdate) => {
          if (mounted) console.log('Received user_timer:', data);
        });

        socket.on('appointment_completed', (data: AppointmentTimerUpdate) => {
          if (mounted) console.log('Received appointment_completed:', data);
        });

        // socket.on('error', (error: any) => {
        //   if (mounted) handleError.set('Socket event error', error);
        // });
      };

      try {
        setupListeners();
        listenersSetRef.current = true;
      } catch (err: any) {
        // if (mounted)
        //   handleError.set(`Failed to setup listeners: ${err.message}`);
        console.error(`Failed to setup listeners: ${err.message}`);
      }
    }

    return () => {
      mounted = false;
      if (roomRef.current) {
        leaveRoom(roomRef.current);
        roomRef.current = null;
      }
      if (listenersSetRef.current && socket) {
        socket.off('next_appointment_updated');
        socket.off('appointment_progress');
        socket.off('user_timer');
        socket.off('appointment_completed');
        socket.off('error');
        listenersSetRef.current = false;
      }
    };
  }, [socket, userId, joinRoom, leaveRoom]);

  const createEventHandler = useCallback(
    <T extends any>(
      eventName: string,
      callback: (data: T) => void
    ): Cleanup => {
      if (!socket) {
        // handleError.set(
        //   `Cannot add ${eventName} handler: Socket not connected`
        // );
        return () => {};
      }

      try {
        socket.on(eventName, callback);
        return () => socket.off(eventName, callback);
      } catch (err: any) {
        //handleError.set(`Failed to setup ${eventName} handler: ${err.message}`);
        return () => {};
      }
    },
    [socket]
  );

  const eventHandlers = useMemo(
    () => ({
      onNextAppointmentUpdated: (
        callback: (data: AppointmentTimerUpdate) => void
      ): Cleanup => createEventHandler('next_appointment_progress', callback),

      onAppointmentProgress: (
        callback: (data: ProgressUpdateData) => void
      ): Cleanup => createEventHandler('appointment_progress', callback),

      onUserTimer: (
        callback: (data: AppointmentTimerUpdate) => void
      ): Cleanup => createEventHandler('user_timer', callback),

      onAppointmentCompleted: (
        callback: (data: {
          completedId: number;
          current: any;
          next: any;
        }) => void
      ): Cleanup => createEventHandler('appointment_completed', callback),
    }),
    [createEventHandler]
  );

  return {
    ...eventHandlers,
    isConnected,
    error: error || socketError,
  };
};
