import React, {
  createContext,
  ReactNode,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useAppointments } from '../hooks/useAppointments';
import { useUserSocket } from '../hooks/useUserSocket';
import { useAuth0 } from '@auth0/auth0-react';
import { Appointment } from '../types/appointments';

interface ExtendedAppointmentsContextProps {
  userAppointments: Appointment[];
  barberAppointments: Appointment[];
  isLoading: boolean;
  fetchUserAppointments: (userId: string) => Promise<void>;
  fetchBarberAppointments: (barberId: string) => Promise<void>;
  removeAppointment: (appointmentId: number) => void;
  rescheduleAppointment: (appointmentId: number, newData: any) => Promise<void>;
  completedAppointments: number[];
  markAppointmentAsCompleted: (appointmentId: number) => void;
}

export const AppointmentsContext = createContext<
  ExtendedAppointmentsContextProps | undefined
>(undefined);

interface AppointmentsProviderProps {
  children: ReactNode;
}

export const AppointmentsProvider: React.FC<AppointmentsProviderProps> = ({
  children,
}) => {
  const mounted = useRef(true);
  const [completedAppointments, setCompletedAppointments] = useState<number[]>(
    []
  );

  const { user } = useAuth0();
  const userId = useMemo(
    () => user?.sub || user?.user_id,
    [user?.sub, user?.user_id]
  );

  // Get appointments data
  const appointmentsData = useAppointments();

  // Memoize first appointment ID
  const firstAppointmentId = useMemo(
    () => appointmentsData.userAppointments[0]?.id || null,
    [appointmentsData.userAppointments]
  );

  // Socket setup with memoized values
  const socketConfig = useMemo(
    () => ({
      userId,
      appointmentId: firstAppointmentId,
      guestLink: null,
    }),
    [userId, firstAppointmentId]
  );

  const { onAppointmentCompleted } = useUserSocket(socketConfig);

  // Memoize appointment completion handler
  const markAppointmentAsCompleted = useCallback((appointmentId: number) => {
    setCompletedAppointments(prev => {
      if (prev.includes(appointmentId)) {
        return prev;
      }
      return [...prev, appointmentId];
    });
  }, []);

  // Memoize appointment removal handler
  const handleRemoveAppointment = useCallback(
    (appointmentId: number) => {
      if (mounted.current) {
        appointmentsData.removeAppointment(appointmentId);
      }
    },
    [appointmentsData.removeAppointment]
  );

  // Socket event listener setup
  useEffect(() => {
    mounted.current = true;

    const cleanup = onAppointmentCompleted(data => {
      if (mounted.current) {
        markAppointmentAsCompleted(data.completedId);
        handleRemoveAppointment(data.completedId);
        console.log(
          'Appointment completed, updated appointments:',
          appointmentsData.userAppointments
        );
      }
    });

    return () => {
      mounted.current = false;
      cleanup();
    };
  }, [
    onAppointmentCompleted,
    markAppointmentAsCompleted,
    handleRemoveAppointment,
  ]);

  // Memoize context value
  const contextValue = useMemo<ExtendedAppointmentsContextProps>(
    () => ({
      userAppointments: appointmentsData.userAppointments,
      barberAppointments: appointmentsData.barberAppointments,
      isLoading: appointmentsData.isLoading,
      fetchUserAppointments: appointmentsData.fetchUserAppointments,
      fetchBarberAppointments: appointmentsData.fetchBarberAppointments,
      removeAppointment: appointmentsData.removeAppointment,
      rescheduleAppointment: appointmentsData.rescheduleAppointment,
      completedAppointments,
      markAppointmentAsCompleted,
    }),
    [
      appointmentsData.userAppointments,
      appointmentsData.barberAppointments,
      appointmentsData.isLoading,
      appointmentsData.fetchUserAppointments,
      appointmentsData.fetchBarberAppointments,
      appointmentsData.removeAppointment,
      appointmentsData.rescheduleAppointment,
      completedAppointments,
      markAppointmentAsCompleted,
    ]
  );

  return (
    <AppointmentsContext.Provider value={contextValue}>
      {children}
    </AppointmentsContext.Provider>
  );
};

// Custom hook with proper error handling
export const useAppointmentsContext = () => {
  const context = React.useContext(AppointmentsContext);
  if (context === undefined) {
    throw new Error(
      'useAppointmentsContext must be used within an AppointmentsProvider'
    );
  }
  return context;
};

// Hook for checking initial loading state
export const useAppointmentsLoading = () => {
  const { isLoading } = useAppointmentsContext();
  return isLoading;
};

// Hook for accessing completed appointments
export const useCompletedAppointments = () => {
  const { completedAppointments, markAppointmentAsCompleted } =
    useAppointmentsContext();
  return { completedAppointments, markAppointmentAsCompleted };
};
