import axios from 'axios';
import { API_SERVER_URL } from '../constants/apiBaseUrlConstants';

const checkBarberSubscription = async (barberId: string | undefined) => {
  try {
    const res = await axios.get(
      `${API_SERVER_URL}api/subscription/${barberId}`
    );
    return res.data.isSubscribed;
  } catch (error) {
    console.error('Error checking subscription status:', error);
    return false;
  }
};

export default checkBarberSubscription;
