import { createContext, useState, useEffect, useMemo } from 'react';

interface IAuthContext {
  isLoggedIn: boolean;
  user: any;
  userFirstName: string;
  role: string;
  login: (token: string, user: any, role: string) => void;
  logout: () => void;
  isJwtChecked: boolean;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = createContext<IAuthContext>({
  isLoggedIn: false,
  user: null,
  userFirstName: '',
  login: () => {},
  logout: () => {},
  role: '',
  isJwtChecked: false,
});

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const [role, setRole] = useState('');
  const [isJwtChecked, setIsJwtChecked] = useState<boolean>(false);
  const [userFirstName, setUserFirstName] = useState<string>('');

  useEffect(() => {
    // Check if a token exists in localStorage
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const role = localStorage.getItem('role');
    if (token && user && role) {
      setUser(user);
      setIsLoggedIn(true);
      setRole(role);
    }
    setIsJwtChecked(true);
  }, []);

  useEffect(() => {
    if (typeof user === 'string') {
      const firstName = user.trim().split(' ')[0];
      setUserFirstName(firstName.charAt(0).toUpperCase() + firstName.slice(1));
    } else if (user && user.name) {
      const firstName = user.name.trim().split(' ')[0];
      setUserFirstName(firstName.charAt(0).toUpperCase() + firstName.slice(1));
    } else {
      setUserFirstName('');
    }
  }, [user]);

  const login = (token: string, user: any, role: string) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('role', role);
    setUser(user);
    setIsLoggedIn(true);
    setRole(role);
    setIsJwtChecked(true);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    setUser(null);
    setIsLoggedIn(false);
    setRole('');
    setIsJwtChecked(false);
  };

  const value = useMemo(
    () => ({
      isLoggedIn,
      user,
      userFirstName,
      login,
      logout,
      role,
      isJwtChecked,
    }),
    [isLoggedIn, user, userFirstName, role, isJwtChecked]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
