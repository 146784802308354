import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { UserRole } from '../types/authRoleInterface';

const useUserRole = () => {
  const {
    isAuthenticated,
    isLoading: auth0Loading,
    getIdTokenClaims,
  } = useAuth0();
  const [userType, setUserType] = useState<UserRole | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserRole = async () => {
      // Don't proceed if Auth0 is still loading
      if (auth0Loading) {
        return;
      }

      // If not authenticated, reset state
      if (!isAuthenticated) {
        setUserType(null);
        setLoading(false);
        return;
      }

      try {
        const claims = await getIdTokenClaims();
        const role = claims?.['https://auth.donvio.com/role'];

        console.log('Fetched Role:', {
          role,
          isAuthenticated,
          auth0Loading,
        });

        setUserType(role || null);
      } catch (error) {
        console.error('Error fetching user role:', error);
        setUserType(null);
      } finally {
        setLoading(false);
      }
    };

    fetchUserRole();
  }, [isAuthenticated, auth0Loading, getIdTokenClaims]);

  return [userType, loading] as const;
};

export default useUserRole;
