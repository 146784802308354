export const COLORS = {
  primary: '#03dac5',
  secondary: '#5a03da',
  tertiary: '#f3f4f6',
  error: '#fc4657',
  warning: '#FFE800',
  success: '#00C853',
  textColor: '#a855f7',
  trailColor: '#eee',
};
